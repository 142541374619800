<template>
  <div>
    
    <div class="flex mb-4">
      <BackButton :href="`../courses/${course.id}/edit`" :cssClass="'mr-4'" />
      <h2 class="text-xl mb-4">{{ course.name }}</h2>
    </div>

    <div class="Box Box--Clear sm:grid sm:grid-cols-2 sm:col-gap-4 mb-4">
      <usersUpload type="enroll" :course="course"  :maxUsers="maxUsers"/>
      <userInvite :currentUser="currentUser" userType="user" :course="course" :fiscalCodeLogin="fiscalCodeLogin" />
    </div>

   <div class="Box Box--Clear mb-4" v-if="course.course_license !== null">
    <p class="font-bold flex items-center gap-2 mb-3">{{t('pages.course.remaining_license')}}: <span class="Tag Tag--Big">{{ course.course_license }}</span></p>
    <p v-if="course.course_license === 0">{{ t('pages.course.no_license') }}</p>
   </div>

    <div class="Form--Boxed mb-8" v-if="course.course_license === null || course.course_license > 0"> 
      <div class="mb-4 flex items-center justify-between">
        <h4 class="TextTooltip">{{t('pages.users.invite_existing')}}
          <button :content="t('pages.users.managing_users')" v-tippy class="Btn--Tooltip">
            <svg height="18" width="18" class="fill-current"> 
              <use xlink:href="#icon-information-outline"></use>
            </svg>
          </button>
        </h4>
        <div>
          <button @click="openModal('enrollAll')" class="Btn Btn--Success">{{t('pages.course.invite_all')}}</button> 
          <button @click="openModal('destroyAll')" class="Btn Btn--Delete">{{t('pages.course.remove_all')}}</button>
        </div>
      </div>
     
      <div class="field flex">
        <input v-model="search" v-on:keyup.enter="getUsers(true)" type="text" :placeholder="t('pages.users.search_user')" class="mr-2"/>
        <button class="Btn Btn--Save" @click="getUsers(true)">{{t('actions.search')}}</button>
      </div>

      <template v-if="allUsers.length > 0">
        <ul class="List--Lined List--Lined--Thin">
          <li v-for="user in allUsers" :key="user.id" class="flex items-center justify-between">
            <span v-if="!user.enrolled" @click="addUser(user)" class="ml-2 cursor-pointer">{{ user.first_name }} {{ user.last_name }} - {{fiscalCodeLogin ? user.fiscal_code : user.email }}</span>
            <span v-else @click="removeUser(user)" class="ml-2 cursor-pointer">{{ user.first_name }} {{ user.last_name }} - {{ fiscalCodeLogin ? user.fiscal_code : user.email }}</span>
            <button class="flex items-center" v-if="!user.enrolled"  @click="addUser(user)">
              <span class="mr-2 text-warning cursor-pointer">{{t('global.not_enrolled_course')}}</span>
              <span class="Btn Btn--CheckStyle  Btn--CheckStyle--UnChecked "></span>
            </button>
            <button v-else class="flex items-center" @click="removeUser(user)">
              <span class="mr-2 text-success cursor-pointer">{{t('global.enrolled_course')}}</span>
              <span class="Btn Btn--CheckStyle  Btn--CheckStyle--Checked">
                <svg height="16" width="16" class="fill-current"> 
                  <use xlink:href="#icon-checkmark"></use>
                </svg>
              </span>
            </button>
          </li>
        </ul> 
        <div class="text-right mt-2">
          <button v-if="addedUsers.length > 0 || removedUsers.length > 0" class="Btn Btn--Save" @click="openModal('addUsers')">{{t('actions.save_changes')}} ({{addedUsers.length+removedUsers.length}} {{t('global.users')}})</button>
        </div>
        <div class="text-center mt-2" >
          <paginate
            v-model="page"
            :page-count="pagy.pages"
            :click-handler="pageCallback"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'PaginationList'">
          </paginate>
        </div>
      </template>
      <template v-else-if="loading">
        <li class="LoadingBox">
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </li>
      </template>
       <div v-else class="pt-8 text-center pb-8">
        <p class="text-lg">{{t('pages.users.no_users')}}</p>
      </div>
    </div>

    <!-- Temporary hide groups for courses with licenses -->
    <div class="Form--Boxed mb-8"  v-if="course.course_license === null">
      <div class="mb-4 flex items-center justify-between">
        <h4>{{t('pages.groups.groups_management')}}</h4>
      </div>
      <template v-if="groups.length > 0">
        <ul class="List--Lined List--Lined--Thin">
          <li v-for="group in groups" :key="group.id" class="flex items-center justify-between">
            <span class="ml-2 cursor-pointer">{{ group.name }}</span>
            <button class="flex items-center" v-if="!group.enrolled" @click="addGroup(group)">
              <span class="mr-2 text-warning cursor-pointer">{{t('global.not_enrolled_course')}}</span>
              <span class="Btn Btn--CheckStyle  Btn--CheckStyle--UnChecked "></span>
            </button>
            <button class="flex items-center" v-else @click="removeGroup(group)">
              <span class="mr-2 text-success cursor-pointer">{{t('global.enrolled_course')}}</span>
              <span class="Btn Btn--CheckStyle  Btn--CheckStyle--Checked">
                <svg height="16" width="16" class="fill-current"> 
                  <use xlink:href="#icon-checkmark"></use>
                </svg>
              </span>
            </button>
          </li>
        </ul>
        <div class="text-right mt-2">
          <button v-if="addedGroups.length > 0 || removedGroups.length > 0" class="Btn Btn--Save" @click="openModal('addGroups')">{{t('actions.save_changes')}} ({{addedGroups.length + removedGroups.length}} {{t('global.groups')}})</button>
        </div>
        <div class="text-center mt-2">
          <paginate
            v-model="groupsPage"
            :page-count="groupsPagy.pages"
            :click-handler="groupsPageCallback"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'PaginationList'">
          </paginate>
        </div>
      </template>
      <template v-else-if="loadingGroups">
        <li class="LoadingBox">
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </li>
      </template>
      <div v-else class="pt-2 text-center">
        <p class="text-left">{{t('pages.groups.empty')}}</p>
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-1" v-if="enabledCourseSettings.length > 0">
      <div class="Form--Boxed mb-8"> 
        <div v-for="courseSetting in enabledCourseSettings" :key="courseSetting.id">
          <editCourseSetting :courseSetting="courseSetting" />
        </div>
      </div>
    </div>

    <modal name="confirm-modal" height="auto">
      <div class="v--modal--header p-4">
        <h2 class="text-xl">{{t('pages.course.editing')}}:</h2>
      </div>
      <div class="v--modal--body body--users p-4">
        <template  v-if="modalType === 'addUsers'">
          <div v-if=" addedUsers.length > 0" class="mb-4">
            <h4 class="mb-2">{{t('pages.course.users_added')}}: ({{ addedUsers.length }})</h4>

            <ul class="v--modal--list List--Lined List--Lined--Thin ColoredScrollBar">
              <li v-for="user in addedUsers" :key="user.id">
              {{ user.email }}
              </li>
            </ul>
          </div>
          <div v-if=" removedUsers.length > 0" class="mb-4">
            <h4 class="mb-2">{{t('pages.course.users_removed')}}: ({{ removedUsers.length }})</h4>
            <ul v-if=" removedUsers.length > 0" class="v--modal--list List--Lined List--Lined--Thin ColoredScrollBar">
              <li v-for="user in removedUsers" :key="user.id">
                {{ user.email }}
              </li>
            </ul>
          </div>
        </template>
        <template  v-else-if="modalType  === 'enrollAll'">
          <p>{{t('pages.course.confirm_invite_all')}}</p>
        </template>
        <template  v-else-if="modalType  === 'destroyAll'">
          <p>{{t('pages.course.confirm_remove_all')}}</p>
        </template>
        <template v-else-if="modalType === 'addGroups'">
          <div v-if="addedGroups.length > 0" class="mb-4">
            <h4 class="mb-2">{{t('pages.groups.groups_added')}}: ({{ addedGroups.length }})</h4>
            <ul class="v--modal--list List--Lined List--Lined--Thin ColoredScrollBar">
              <li v-for="group in addedGroups" :key="group.id">
                {{ group.name }}
              </li>
            </ul>
          </div>
          <div v-if="removedGroups.length > 0" class="mb-4">
            <h4 class="mb-2">{{t('pages.groups.groups_removed')}}: ({{ removedGroups.length }})</h4>
            <ul v-if="removedGroups.length > 0" class="v--modal--list List--Lined List--Lined--Thin ColoredScrollBar">
              <li v-for="group in removedGroups" :key="group.id">
                {{ group.name }}
              </li>
            </ul>
          </div>
        </template>
      </div>
      <div class="v--modal--actions sm:flex sm:justify-end p-4">
        <button class="Btn Btn--Save" v-if="modalType === 'addUsers' || modalType === 'enrollAll' || modalType === 'destroyAll'" @click="saveUsers">{{t('actions.save')}}</button>
        <button class="Btn Btn--Save" v-if="modalType === 'addGroups'" @click="saveGroups">{{t('actions.save')}}</button>
        <button class="Btn Btn--Edit ml-2" @click="$modal.hide('confirm-modal')">{{t('actions.undo')}}</button>
      </div>
    </modal>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import api from '../../../api'
import BackButton from '../../commons/backButton'
import usersUpload from '../../commons/usersUpload'
import userInvite from '../../commons/userInvite'
import progressBar from '../../commons/progressBar'
import editCourseSetting from '../../commons/editCourseSetting'
import { global as EventBus } from '../../../js/utils/eventBus'

export default {
  props: {
    course: Object,
    currentUser: Object,
    courseSettings: Array,
    maxUsers: Number,
    fiscalCodeLogin: Boolean
  },
  components: {
    usersUpload,
    userInvite,
    editCourseSetting,
    progressBar,
    BackButton
  },
  data() {
    return {
      files: [],
      addedUsers: [],
      removedUsers: [],
      search: "",
      page: 1,
      pagy: {},
      loading: false,
      pageOfItems: [],
      customLabels: {
        first: '<<',
        last: '>>',
        previous: '<',
        next: '>'
      },
      enrollAll: false,
      destroyAll: false,
      modalType: "",
      groups: [],
      loadingGroups: false,
      groupsPage: 1,
      groupsPagy: {},
      addedGroups: [],
      removedGroups: [],
      initiallyEnrolledGroups: []
    }
  },
  
  computed: {
    ...mapState({
      allUsers: state => state.user.allUsers
    }),
    enabledCourseSettings(){
      return this.courseSettings.filter( setting =>{
        return setting.enabled
      })
    },
    addedUsersIds() {
      return this.addedUsers.map(el => el.id)
    },
    addedGroupsIds() {
      return this.addedGroups.map(el => el.id)
    },
    removedGroupsIds() {
      return this.removedGroups.map(el => el.id)
    }
  },
  mounted() {
    this.getUsers(),
    this.getGroups()
  },
  methods: {
    ...mapActions({
      getAllUsers: 'getAllUsers',
      addEnrolls: 'addEnrolls'
    }),
     onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    getUsers(fromSearch){
      this.loading = true
      api.User.getUsersFromSearch({
        user_type: 'all', 
        page:  fromSearch ? 1 : this.page,
        search: this.search,
        course_id:  this.course.id
      })
      .then( res => {
        this.getAllUsers(res.users)
        this.pagy = res.pagy
        this.loading = false
        this.addedUsers = [] 
        this.removedUsers = []
      })
      .catch(err => {
        this.loading = false
        console.log(err)
      })
    },
    getGroups() {
      this.loadingGroups = true;
      api.Group.getGroups({ page: this.groupsPage, course_id: this.course.id })
        .then(res => {
          console.log("Gruppi caricati:", res.groups);
          this.groups = res.groups;
          this.groupsPagy = res.pagy;
          this.loadingGroups = false;
          this.groups.forEach(group => {
            if (!group.courses) {
              group.courses = [];
            }
            if (group.enrolled) {
              this.initiallyEnrolledGroups.push(group);
            }
          });
        })
        .catch(err => {
          this.loadingGroups = false;
          console.error("Errore durante il caricamento dei gruppi:", err);
        });
    },
    addUser(user){
      user.enrolled = true
      
      // user was enrolled but added to removes user. doesn't have to be added
      if ( this.removedUsers.filter(item => item.id === user.id ).length > 0  ) {
        this.removedUsers = this.removedUsers.filter(function( obj ) {
          return obj.id !== user.id;
        });
      } else {
        this.addedUsers.push(user)
      }
    },
    addGroup(group) {
      group.enrolled = true

      if (this.removedGroups.some(item => item.id === group.id)) {
        this.removedGroups = this.removedGroups.filter(obj => obj.id !== group.id)
      } else {
        this.addedGroups.push(group)
      }
    },
    removeGroup(group) {
      group.enrolled = false

      if (this.addedGroups.some(item => item.id === group.id)) {
        this.addedGroups = this.addedGroups.filter(obj => obj.id !== group.id)
      } else {
        this.removedGroups.push(group)
      }
    },
     pageCallback(pageNum){
      console.log(pageNum)
      if (pageNum != undefined) {
        this.page = pageNum
      }
      this.getUsers()
    },
    groupsPageCallback(pageNum) {
      if (pageNum != undefined) {
        this.groupsPage = pageNum;
      }
      this.getGroups();
    },
    removeUser(user){
      user.enrolled = false
      // user was not enrolled but added to added user. doesn't have to be removed
      if ( this.addedUsers.filter(item => item.id === user.id ).length > 0  ) {
        this.addedUsers = this.addedUsers.filter(function( obj ) {
          return obj.id !== user.id;
        });
      } else {
        this.removedUsers.push(user)
      }
      
    },
  
    openModal(modalType) {
      this.modalType = modalType
      this.$modal.show('confirm-modal');
    },
    async saveUsers() {
      const elem = event.currentTarget;
      this.enrollAll = this.modalType === 'enrollAll';
      this.destroyAll = this.modalType === 'destroyAll';

      EventBus.emit('loader:local:start', { elem });

      // Crea le promesse senza await
      const createEnrolls = api.Enroll.createEnrolls(this.addedUsersIds, this.course.id, this.enrollAll);
      const destroyEnrolls = api.Enroll.destroyEnrolls(this.removedUsers, [this.course.id], this.destroyAll);

      try {
        // Usa Promise.all per aspettare entrambe le promesse
        const res = await Promise.all([createEnrolls, destroyEnrolls]);

        // Successo
        this.addedUsers = [];
        this.removedUsers = [];
        this.$modal.hide('confirm-modal');
        EventBus.emit('loader:local:end', { elem });
        if (this.enrollAll || this.destroyAll) location.reload();
      } catch (err) {
        // Errore
        this.$toastr.e(err.response.data.error);
        EventBus.emit('loader:local:end', { elem });
      }
    },

    
    async saveGroups() {
      const elem = event.currentTarget
      EventBus.emit('loader:local:start', { elem })
      const createGroupEnrolls = api.GroupCourse.createGroupEnrolls(this.addedGroupsIds, this.course.id)
      const initiallyEnrolledGroupIds = this.initiallyEnrolledGroups.map(group => group.id)
      const groupsToUnenroll = initiallyEnrolledGroupIds.filter(id => this.removedGroupsIds.includes(id))
      let destroyGroupEnrolls = Promise.resolve()
      if (groupsToUnenroll && groupsToUnenroll.length > 0) {
        destroyGroupEnrolls = api.GroupCourse.destroyGroupEnrolls(groupsToUnenroll, [this.course.id])
      }

      try {
        const res = await Promise.all([createGroupEnrolls, destroyGroupEnrolls])
        this.addedGroups = []
        this.removedGroups = []
        this.initiallyEnrolledGroups = []
        this.$modal.hide('confirm-modal')
        EventBus.emit('loader:local:end', { elem })
      } catch (err) {
        this.$toastr.e(this.t('errors.messages.try_again'))
        EventBus.emit('loader:local:end', { elem })
      }
      
    }
 
  }
}
</script>