import api from "./instance";

const update = (id, params) =>
  api
    .put(`/shipping_addresses/${id}`, params)
    .then((response) => response.data);

const create = (params) =>
  api.post(`/shipping_addresses`, params).then((response) => response.data);

const destroy = (id) =>
  api.delete(`/shipping_addresses/${id}`).then((response) => response.data);

export default {
  create,
  update,
  destroy,
};
