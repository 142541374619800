<template>
  <div>
    <div v-if="courseSetting.is_boolean" class="mb-4">
      <div class="field flex items-center field--checkbox">
        <input type="checkbox" :id="`setting-${courseSetting.id}`" v-model="courseSetting.return_value" @change="updateCourseSetting()">  
        <label class="ml-2" :for="`setting-${courseSetting.id}`">{{ t(`pages.course.${courseSetting.label}`) }} </label>
      </div>
    </div>
    <settingWithImage v-else-if="courseSetting.is_image" :setting="courseSetting" settingClass="courseSetting" settingType="certification_template" :name="t('pages.course.certification_template')" />
    <div v-else class="mb-4">
      <label :for="`setting-${courseSetting.id}`" class="">
        {{ t(`pages.course.${courseSetting.label}`) }}
      </label>
      <div class="flex items-center gap-2">
        <input 
          type="text" 
          :id="`setting-${courseSetting.id}`" 
          v-model="courseSetting.return_value" 
          @input="logValue"
          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
        <button
          class="Btn Btn--Save" 
          @click.prevent="updateCourseSetting()"
        >
          {{ t('actions.save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../api'
import settingWithImage from '../commons/settingWithImage'

export default {
  props: {
    courseSetting: Object,
  },
  data() {
    return {
      cta: "save"
    }
  },
  components: {
    settingWithImage
  },
  methods: {
    updateCourseSetting(event){
      const target = event?.currentTarget;
      let self = this
      self.cta = "saving"
      api.CourseSetting.update(self.courseSetting)
      .then( res => {
        self.cta = "saved"
        this.$toastr.s(this.t('actions.saved'));
        setTimeout(function () {
          self.cta = "save"
        }, 3000);
        self.courseSetting = res
      })
      .catch(e =>{
        self.cta = "error"
        this.$toastr.e(this.t('errors.messages.try_again'));
      })
    }
  }
}
</script>