<template>
  <div>
    <h1 class="text-xl"><a class="Btn Btn--Primary Btn--Icon mb-2 mr-4" href="/users/edit">
      <svg height="16" width="16" class="icon-arrow-left fill-current ">
        <use xlink:href="#icon-arrow-left"></use>
      </svg>
    </a>{{ t('pages.home.your_certification') }}</h1>
    <div v-if="loading" class="LoadingBox mt-4 text-center">
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
    <div  v-else-if="certifications.length === 0"  class="Box Box--Clear text-center gap-4">
      <h2 class="mb-5 text-lg">{{ t('pages.home.no_certifications_available') }}</h2>
      <img src="../../images/illustrations/certifications.png" class="max-w-sm	ml-auto mr-auto w-full"/>
    </div>
    <div v-else >
      <div class="List--Boxed mt-4 p-4">
        <ul class="List-SingleBoxes">
          <li v-for="certification in certifications" :key="certification.id">
            {{ certification.name || 'Nome non disponibile' }}
            <div class="List-SingleBoxes-btns">
              <a v-if="certification.linkedin_organization_id[0].value !== null && certification.linkedin_organization_id[0].value !== ''"
                :href="`https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${certification.name}&organizationId=${certification.linkedin_organization_id[0].value}&issueYear=${new Date(certification.completed_date).getFullYear()}&issueMonth=${new Date(certification.completed_date).getMonth() + 1}&certId=${certification.unique_identifier}&certUrl=${baseUrl}/certifications/${certification.unique_identifier}.pdf`"
                target="_blank"
                style="color:#0077B5;display: flex; align-items: center; text-decoration: none;"
              >
                <img :src="t('pages.home.linkedin_button_image')" :alt="t('pages.home.share_on_linkedin')" style="height: 24px;"/>
              </a>

              <a :href="'/certifications/' + certification.unique_identifier + '.pdf'" target="_blank" style="color:#2AC76F;display: flex; align-items: center;">
                {{ t('pages.home.download_certification') }}
                <svg height="16" width="16" color="#2AC76F" class="fill-current ml-2"> 
                  <use xlink:href="#icon-download"></use>
                </svg>
              </a>
            </div>
          </li>
        </ul>
      </div>
      <div class="text-center mt-2">
        <paginate
          v-model="currentPage"
          :page-count="pagy.pages"
          :click-handler="onChangePage"
          :prev-text="'<'"
          :next-text="'>'"
          :container-class="'PaginationList'">
        </paginate>
      </div>
    </div>
   
  </div>
</template>

<script>

import api from '../../api'

export default {
  props: {
    initialPage: Number
  },
  data() {
    return {
      certifications: [],
      pagy: {},
      currentPage: 1,
      loading: false,
      baseUrl: window.location.origin,
    };
  },
  mounted() {   
    this.getCertifications((this.initialPage || 1));
  },
  methods: {
  getCertifications(page) {
    this.loading = true;
    api.Certification.getCertifications({ page: page })
      .then(response => {
        console.log('Response:', response);
        this.certifications = response.certifications;
        this.pagy = response.pagy;
        this.loading = false;
        console.log('Certificazioni:', this.certifications);
        this.certifications.forEach(cert => {
          console.log('Data creazione:', cert.certification_course?.created_at || 'Non disponibile');
          console.log('Certification Details:', {
          name: cert.name,
          linkedinOrganizationId: cert.linkedin_organization_id[0].value,
          completed_date: cert.completed_date,
          uniqueIdentifier: cert.unique_identifier
        });
        });
      })
      .catch(error => {
        console.error('There has been a problem with your fetch operation:', error);
        this.loading = false;
      });
  },
  onChangePage(page) {
    this.currentPage = page;
    this.getCertifications(page);
  },
}
}
</script>
