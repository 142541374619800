<template>
  <div :class="['Thumbnail', {'Thumbnail--WithImage': course.image_url}]" :id="`course_${course.id}`" >
    <div class="text-left mb-2 flex items-center">
      <span class="Tag m-0">{{course.path ? t('global.path') : t('global.courses.s')}}</span>
      <span v-if="course.product_type" class="Tag m-0">{{course.product_type}}</span>
      <span v-if="course.path_only" class="Tag m-0">bundle-only</span>
      <span v-if="course.language" class="Tag Tag m-0">{{ course.language }}</span>
      <span v-if="course.points" class="Tag Tag m-0 inline-flex items-center">
        <svg height="10" width="10" class="fill-current mr-1"> 
          <use xlink:href="#icon-badge"></use>
        </svg>
        {{ course.points }}
      </span>
      <span v-if="!admin" v-for="area in course.areas.slice(0, 3)" :key="area.id" class="Tag m-0">{{ area.name }}</span>
      <template v-if="admin">
        <span v-if="course.public" class="Tag Tag--Success m-0">{{t('global.public')}}</span>
        <span v-else class="Tag Tag--Danger  m-0">{{t('global.draft')}}</span>
      </template>
    </div>
    <h4 :class="['Thumbnail__Title sm:text-xl', {'mb-4' : !admin}, {'mb-4 sm:mb-10': admin}]">{{course.name}}</h4>
    <!-- <p class="Thumbnail__Description mb-2" v-if="!admin" v-html="course.subtitle" /> -->
    <div class="Thumbnail__Bottom flex flex-wrap sm:justify-end gap-2" v-if="admin">
        <a v-if="course.show_comments" :href="`/admin/courses/${course.id}/forum`" class="Btn Btn--Text" data-turbolinks="false">Forum</a>
        <a v-if="user.admin_role === 'superAdmin'" :href="`/admin/courses/${course.id}/edit`" class="Btn Btn--Edit" data-turbolinks="false">{{t('actions.edit')}}</a>
        <a v-else :href="`/courses/${course.id}/${course.path ? 'path' : 'page'}?preview=true`" target="_blank" class="Btn Btn--Edit">Preview</a>
        <a :href="`/admin/courses/${course.id}/stats`" class="Btn Btn--Primary" data-turbolinks="false">{{t('global.stats')}}</a>
        <a v-if="user.admin_role != 'inspector'" :href="`/admin/courses/${course.id}`" class="Btn Btn--Success" data-turbolinks="false">{{t('global.users')}}</a>
    </div>
    <div class="Thumbnail__Bottom flex justify-between mt-6 items-center" v-else>
      <span v-if="course.progress_status" class="sm:text-xl font-bold">{{  t(`pages.course.${course.progress_status}`) }}</span>
      <span class="sm:text-2xl font-bold" v-else-if="course.show_percentage">{{course.percentageCompleted}}</span>
      <a :href="`/certifications/${course.completedCourse.unique_identifier}`" v-if="course.completedCourse && course.certification" class="Btn Btn--Text" target="_blank">
        <span class="hidden sm:block">{{ t('pages.home.certification')}}</span>
        <svg height="16" width="16" class="fill-current ml-2"> 
          <use xlink:href="#icon-download"></use>
        </svg>
      </a>
      <a v-if="course.show_comments" :href="`/courses/${course.id}/forum`" class="Btn Btn--Text" data-turbolinks="false">Forum</a>
      <a :href="`/courses/${course.id}/${course.path ? 'path' : 'page'}`" class="Btn Btn--Primary Btn--Arrow" data-turbolinks="false">
        {{ t(`actions.${course.percentage_value > 0  || course.progress_status == 'in_progress' ? 'continue' : 'start'}`) }}
        <svg height="16" width="16" class="fill-current"> 
          <use xlink:href="#icon-arrow-right"></use>
        </svg>
      </a>
    </div>
    <img class="Thumbnail__Img" :src="course.image_url" alt="" v-if="course.image_url">

  </div>
</template>

<script>
import course from '../../api/course'
import user from '../../api/user'

export default {
  props: {
    course: Object,
    user: Object,
    admin: Boolean
  },

  computed: {
    
  },
  methods: {
    shortDescription(description) {
      if (description) {
        if (description.length > 100) {
          return `${description.substr(0, 100)}...`
        } else {
          return description
        }
      } 
      return ''
    },
    
  }
}
</script>