<template>
  <div class="EditSection mb-4">
    <div class="flex justify-between mb-4">
      <div class="flex items-center">
        <BackButton :href="`/admin/gamification/prizes`" :cssClass="'mr-4'" />
        <h2 class="text-2xl font-bold">{{ prize.name }}</h2>
      </div>
      <div class="ToggleContainer field flex items-center field--checkbox gap-2">
        <Transition name="fade">
          <button @click.prevent="updateEnabled(enabled)"  v-if="isEnabledChanged && enabled !== initialEnabled" :class="['Btn', {'Btn--Save': enabled, 'Btn--Delete': !enabled}]" type="submit">
            {{ enabled ? t(`actions.save_to_activate`) : t(`actions.save_to_deactivate`) }}
          </button>
        </Transition>
        <label for="cn-enabled" :class="['text-lg font-bold', {'text-danger': !enabled, 'text-success': enabled}]">{{ t(`global.prize.s`)}}  {{ !enabled ? t('global.not') : '' }} {{t('global.active.s') }}</label>
        <label for="cn-enabled" class="Switch">
          <input v-model="enabled" id="cn-enabled" type="checkbox">
          <span class="Slider round"></span>
        </label>
      </div>
    </div>
    <form class="Form--Boxed mb-4">
      <div class="EditSection__Infos">
        <div class="grid grid-cols-3 gap-3">
          <div class="field">
            <label for="points" class="inline-block text-lg">{{t('pages.prizes.name')}}</label>
            <input v-model="params.name" id="points" class="mb-4" type="text" :placeholder="t('pages.prizes.points')"
              aria-label="Punti" />
          </div>
          <div class="field">
            <label for="points" class="inline-block text-lg">{{t('pages.prizes.points')}}</label>
            <input v-model="params.points" id="points" class="mb-4" type="text" :placeholder="t('pages.prizes.points')"
              aria-label="Punti" />
          </div>
          <div class="field">
            <label for="stock" class="inline-block text-lg">{{t('pages.prizes.stock')}}</label>
            <input v-model="params.stock" id="stock" class="mb-4" type="number" :placeholder="t('pages.prizes.stock')" aria-label="Stock" />
          </div>
        </div>
 
        <div class="flex items-start field--checkbox mb-4">
          <input type="checkbox" v-model="params.requires_shipping" id="requires_shipping" class="mt-2">  
          <label class="ml-2 font-bold text-lg" for="hide_academy_name"> 
            {{ t('pages.prizes.requires_shipping') }}
            <button :content="t('pages.prizes.requires_shipping_hint')" v-tippy class="Btn--Tooltip ml-2">
              <svg height="18" width="18" class="fill-current"> 
                <use xlink:href="#icon-information-outline"></use>
              </svg>
            </button>
          </label>
        </div>

        <div class="flex justify-end">
          <button @click.prevent="updatePrize(params)" class="Btn Btn--Save" type="submit">
            {{ t(`actions.save`) }}
          </button>
        </div>
      </div>
    </form>
    <div class="field">
      <label for="cn-langt" class="text-lg font-bold mb-2">{{t('global.language')}}</label>
      <select v-model="selectedLang" id="cn-lang" class="Select mb-4"  aria-label="Lang">
        <option class="" v-for="lang in languages" :value="lang.code">{{ lang.code }}</option>
      </select>
    </div>
    <form class="Form--Boxed mb-4">
      <div class="EditSection__Infos" v-for="translation in translations">
        <div  v-if="selectedLang === translation.language">
          <div class="field">
            <label for="p-name" class="text-lg">{{t('pages.prizes.name')}}</label>
            <input v-model="translation.name" id="p-name" class="mb-4" type="text" placeholder=""
              aria-label="Nome" />
          </div>
          <div class="field">
            <label for="p-desc" class="text-lg">{{t('pages.prizes.description')}}</label>
            <input v-model="translation.description" id="p-desc" class="mb-4" type="text" placeholder=""
              aria-label="Description" />
          </div>
          <div class="flex justify-end">
            <button @click.prevent="updateTranslation(translation)" class="Btn Btn--Save" type="submit">
              {{ t(`actions.save`) }}
            </button>
          </div>
        </div>
        
      </div>
    </form>
    <div class="Form--Boxed mb-4">
      <uploadMedia
        id="prize-image"
        :label="t('global.image')"
        :preview="prize.url"
        accept="image/*"
        :post-action="getPostUploadUrl()"
      >
        <template v-slot:preview>
          <img v-if="prize.url" :src="prize.url" width="200px" class="mb-4">
        </template>
      </uploadMedia>
    </div>

  </div>
</template>

<script>
import api from '../../../api'
import BackButton from '../../commons/backButton'
import uploadMedia from '../../commons/uploadMedia'
import { global as EventBus } from '../../../js/utils/eventBus';


export default {
  name: 'AdminPrizeEdit',
  props: {
    prize: Object,
    languages: Array
  },
  components: {
    BackButton,
    uploadMedia
  },
  data() {
    return {
      params: {
        name: this.prize.name,
        points: this.prize.points,
        stock: this.prize.stock || 0,
        requires_shipping: this.prize.requires_shipping
      },
      translations: this.prize.translations,
      selectedLang: 'it',
      enabled: this.prize.enabled,
      initialEnabled: this.prize.enabled,
    }
  },

  watch: {},
  computed: {},
  mounted() {},

  methods: {

    isEnabledChanged() {
      return this.enabled !== this.prize.enabled
    },

    updatePrize(params) {
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target })
      api.Prize.update(this.prize.id, params)
        .then(res => {
          this.prize = res;
          this.params = {...params};
          this.$toastr.s(this.t('actions.saved'));
          EventBus.emit('loader:local:end', { elem: target })
        })
        .catch(err => { 
          EventBus.emit('loader:local:end', { elem: target })
          this.$toastr.e(this.t('actions.error'));
        })
    },

    updateTranslation(translation) {
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target })
      api.Prize.updateTranslation(this.prize.id, {
        translation_id: translation.id,
        name: translation.name,
        description: translation.description
      })
        .then(res => {
          this.$toastr.s(this.t('actions.saved'));
          EventBus.emit('loader:local:end', { elem: target })
        })
        .catch(err => {
          EventBus.emit('loader:local:end', { elem: target })
          this.$toastr.e(this.t('actions.error'));
        })
    },

    getPostUploadUrl() {
      return `/admin/gamification/prizes/${this.prize.id}/upload`
    },

    updateEnabled(enabled) {
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target })
      api.Prize.update(this.prize.id, { enabled })
        .then(res => {
          this.prize = res;
          this.$toastr.s(this.t('actions.saved'));
          EventBus.emit('loader:local:end', { elem: target })
        })
        .catch(err => {
          EventBus.emit('loader:local:end', { elem: target })
          this.$toastr.e(this.t('actions.error'));
        })
    },

  }
}

</script>
<style lang="scss">

#cn-lang {
  text-transform: uppercase;
  max-width: 200px;
}

</style>