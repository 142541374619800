  <template>
    <div class="body-wrap">
      <div class="container container--toleft">
        <div class="WidgetGamification Box Box--Clear flex  mb-6">
          <div>
            <h1 class="text-2xl mb-3 font-bold capitalize">
              {{t('pages.gamification.prizes.title')}}
            </h1>
            <p class="text-lg mb-3">
              {{t('pages.gamification.prizes.description')}}
            </p>
            <div class="flex items-center gap-3">
              <p class="text-lg mr-2">
                {{t('pages.gamification.prizes.accumulated')}}
              </p>
              <div class="IconTextBox flex-1">
                <h2 class="text-lg mr-2">{{user.gamification_points}} {{t('global.gamification.points')}}</h2>
                <svg height="16" width="16" class="icon-currency-dollar fill-current Boxed">
                  <use xlink:href="#icon-currency-dollar"></use>
                </svg>
              </div>
            </div>
          </div>
          <!--img src="../../../images/illustrations/stats.png" class="max-w-sm  ml-auto mr-auto w-full"/-->
        </div>
        <div>
          <div>
            <ul class="Prizes__List grid sm:grid-cols-4 gap-4">
              <div :id="'prize_'+prize.id" class="WidgetPrize Box Box--Clear flex flex-col justify-end"
                v-for="prize in prizes" :key="prize.id"
                :disabled="user.gamification_points < prize.points"
                :class="{ 'disabled': user.gamification_points < prize.points }"
              >
                <div class="WidgetPrize__Image" :style="{ backgroundImage: `url(${prize.url})`}" ></div>
                <div class="WidgetPrize__Bottom p-3">
                  <h4 class="sm:text-xl mb-4 w-full">{{translationName(prize)}}</h4>
                  <div class="flex flex-wrap sm:justify-between gap-2 items-center">
                    <div class="">
                      {{prize.points}} {{t('global.gamification.points')}}
                    </div>
                    <button class="Btn Btn--Primary"
                      :disabled="prize.stock === 0 || user.gamification_points < prize.points"
                      @click="openConfirmModal(prize)"
                      v-if="notRequested(prize)"
                    >
                      {{ prize.stock === 0 ? t('pages.gamification.prizes.out_of_stock') : t('pages.gamification.prizes.request') }}
                    </button>
                    <div class="" v-else>
                      {{t('pages.gamification.prizes.requested')}}
                    </div>
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>

      <modal v-if="currentPrize" name="request" height="auto">
        <div class="v--modal--header v--modal--header--prize p-4">
          <img :src="currentPrize.url" class="max-w-sm  ml-auto mr-auto"/>
        </div>
        <template v-if="feedback">
          <div class="v--modal--body body--users p-4">
            <h2 class="text-xl">{{translationName(currentPrize)}}</h2>
            <p class="">{{ translationDescription(currentPrize) }}</p>
            <p class="mt-4">{{t('pages.gamification.prizes.requested_success')}}</p>
          </div>
          <div class="v--modal--actions sm:flex sm:justify-end p-4">
            <button class="Btn Btn--Edit" @click="closeModal">
              {{t('actions.close')}}
            </button>
          </div>
        </template>
        <template v-else>
          <div class="v--modal--body body--users p-4">
            <h2 class="text-xl">{{translationName(currentPrize)}}</h2>
            <p class="mb-2">{{ translationDescription(currentPrize) }}</p>

            <div v-if="currentPrize.requires_shipping">
              <div v-if="shippingAddresses.length" class="mb-4">
                <h3 class="text-lg mt-4">{{ t('pages.gamification.prizes.select_address') }}</h3>
                <div v-for="address in shippingAddresses" :key="address.id" class="border rounded-lg p-3 mt-2">
                  <div class="flex items-center gap-3">
                    <input 
                      type="radio" 
                      :id="'address_' + address.id" 
                      v-model="selectedAddressId" 
                      :value="address.id" 
                      class=""
                    />
                    <label :for="'address_' + address.id">
                      {{ address.full_name }}, {{ address.address }}, {{ address.city }} ({{ address.country }})
                    </label>
                    <button class="Btn Btn--Text text-warning text-sm ml-auto" v-if="!editingAddress" @click="editAddress(address)">
                      {{ t('actions.edit') }}
                    </button>
                  </div>
                  <div v-if="editingAddress && editingAddress.id === address.id" class="mt-4">
                    <h3 class="text-lg mb-2">{{ t('pages.gamification.prizes.edit_address') }}</h3>
                    <div class="grid grid-cols-2 gap-4">
                      <input v-model="editingAddress.full_name" type="text" :placeholder="t('pages.gamification.prizes.shipping_address.full_name')" class="" />
                      <input v-model="editingAddress.address" type="text" :placeholder="t('pages.gamification.prizes.shipping_address.address')" class="" />
                      <input v-model="editingAddress.city" type="text" :placeholder="t('pages.gamification.prizes.shipping_address.city')" class="" />
                      <input v-model="editingAddress.province" type="text" :placeholder="t('pages.gamification.prizes.shipping_address.province')" class="" />
                      <input v-model="editingAddress.zip_code" type="text" :placeholder="t('pages.gamification.prizes.shipping_address.zip_code')" class="" />
                      <input v-model="editingAddress.country" type="text" :placeholder="t('pages.gamification.prizes.shipping_address.country')" class="" />
                      <input v-model="editingAddress.phone" type="text" :placeholder="t('pages.gamification.prizes.shipping_address.phone')"class="col-span-2"  />
                      <textarea v-model="editingAddress.additional_info" :placeholder="t('pages.gamification.prizes.shipping_address.additional_info')"class="col-span-2" />
                    </div>
                    <button class="Btn Btn--Save mt-2" @click="updateAddress(editingAddress)">
                      {{ t('actions.save') }}
                    </button>
                    <button class="Btn Btn--Edit ml-2 mt-2" @click="cancelEdit">
                      {{ t('actions.undo') }}
                    </button>
                  </div>
                </div>
              </div>


              <template v-if="!editingAddress">
                <div>
                  <button class="Btn Btn--Text"  v-if="!newAddressOpened" @click="newAddressOpened = true">
                    {{  shippingAddresses.length > 0 ? 'Inserisci un nuovo indirizzo di spedizione' : 'Inserisci un indirizzo di spedizione' }}
                  </button>
                </div>

                  <!-- Create New Address -->
                <div v-if="newAddressOpened" class="border rounded-lg p-3">
                  <h3 class="text-lg flex justify-between">
                    <span>{{ t('pages.gamification.prizes.new_address') }}</span>
                    <button class="Btn Btn--Text text-danger" v-if="newAddressOpened" @click="newAddressOpened = false">{{ t('actions.close') }}</button>
                  </h3>
                  <p class="mb-2">{{ t('pages.gamification.prizes.new_address_hint') }}</p>
                  <div class="grid grid-cols-2 gap-4 mb-2">
                    <input v-model="newAddress.full_name" type="text" :placeholder="t('pages.gamification.prizes.shipping_address.full_name')" class="" />
                    <input v-model="newAddress.address" type="text" :placeholder="t('pages.gamification.prizes.shipping_address.address')"  class="" />
                    <input v-model="newAddress.city" type="text" :placeholder="t('pages.gamification.prizes.shipping_address.city')" class="" />
                    <input v-model="newAddress.province" type="text" :placeholder="t('pages.gamification.prizes.shipping_address.province')" class="" />
                    <input v-model="newAddress.zip_code" type="text" :placeholder="t('pages.gamification.prizes.shipping_address.zip_code')" class="" />
                    <input v-model="newAddress.country" type="text" :placeholder="t('pages.gamification.prizes.shipping_address.country')" class="" />
                    <input v-model="newAddress.phone" type="text" :placeholder="t('pages.gamification.prizes.shipping_address.phone')" class="col-span-2"  />
                    <textarea v-model="newAddress.additional_info" :placeholder="t('pages.gamification.prizes.shipping_address.additional_info')" class="col-span-2" />
                  </div>
                  <button class="Btn Btn--Save mt-2" @click="createNewAddress">
                    {{ t('actions.add') }}
                  </button>
                </div>
              </template>
            </div>
            

          </div>
          <div class="v--modal--actions sm:flex sm:justify-end p-4" v-if="!editingAddress && !newAddressOpened">
            <button v-if="canRequestPrize" class="Btn Btn--Save" @click="requestPrize">{{t('actions.request')}}</button>
            <button class="Btn Btn--Edit ml-2" @click="closeModal">
              {{t('actions.undo')}}
            </button>
          </div>
        </template>
      </modal>

    </div>
  </template>

  <script>
  import api from '../../api'
  import prize from '../../api/prize';
  import { global as EventBus } from '../../js/utils/eventBus'

  export default {
    name: 'AdminPrizeEdit',
    props: {
      currentUser: Object,
      prizes: Array,
      requests: Array,
      academyLang: String,
    },

    data() {
      return {
        currentPrize: null,
        user: this.currentUser,
        feedback: false,
        newAddressOpened: false,
        shippingAddresses: [],
        selectedAddressId: null,
        editingAddress: null,
        newAddress: {
          id: null,
          full_name: '',
          address: '',
          city: '',
          province: '',
          zip_code: '',
          country: '',
          phone: '',
          additional_info: '',
        },
      }
    },

    mounted() {
      console.log(this.currentUser)
      this.getShippingAddresses()
    },

    computed: {

      userLang () {
        return this.currentUser.lang || this.academyLang
      },

      canRequestPrize() {
        return this.selectedAddressId || this.isNewAddressValid
      },

      isNewAddressValid() {
        return (
          this.newAddress.full_name &&
          this.newAddress.address &&
          this.newAddress.city &&
          this.newAddress.province &&
          this.newAddress.zip_code &&
          this.newAddress.country &&
          this.newAddress.phone
        )
      },

  
    },
      

    methods: {

      getShippingAddresses() {
        api.User.getShippingAddresses(this.currentUser)
          .then(res => {
            this.shippingAddresses = res

            // Preselect the preferred address if available
            const preferred = this.shippingAddresses.find(a => a.preferred)
            if (preferred) this.selectedAddressId = preferred.id
          })
          .catch(err => {
            this.$toastr.e(this.t('actions.error'))
          })
      },

      translationName(prize) {
        return  prize.translations.find(t => t.language == this.userLang).name
      },

      translationDescription(prize) {
        return  prize.translations.find(t => t.language ==  this.userLang).description
      },

      openConfirmModal(prize) {
        this.currentPrize = prize
        setTimeout(() => {
          this.$modal.show('request')
        }, 200)
      },
      requestPrize() {
        const elem = event.currentTarget
        EventBus.emit('loader:local:start', { elem })
        api.Prize.createRequest(this.currentPrize.id, {user_id: this.user.id, shipping_address_id: this.selectedAddressId})
          .then(res => {
            this.user = res;
            this.$toastr.s(this.t('actions.saved'));
            EventBus.emit('loader:local:end', { elem })
            this.feedback = true
          })
          .catch(err => {
            EventBus.emit('loader:local:end', { elem })
            this.$toastr.e(this.t('actions.error'));
          })
      },
      closeModal() {
        this.currentPrize = null
        this.$modal.hide('request')
      },
      notRequested(prize) {
        return this.requests.find((r) => r.prize_id == prize.id) == null
      },
      editAddress(address) {
      this.editingAddress = { ...address }
    },

      cancelEdit() {
        this.editingAddress = null
      },

      updateAddress(address) {
        api.shippingAddres.update(address.id, address)
          .then(() => {
            this.$toastr.s(this.t('actions.saved'))
            this.getShippingAddresses()
            this.cancelEdit()
          })
          .catch(() => {
            this.$toastr.e(this.t('actions.error'))
          })
      },

      createNewAddress() {
        api.shippingAddres.create({
          user_id: this.currentUser.id,
          ...this.newAddress
        })
        .then(() => {
          this.$toastr.s(this.t('actions.saved'))
          this.newAddressOpened = false
          this.getShippingAddresses()
          this.newAddress = {
            full_name: '',
            address: '',
            city: '',
            province: '',
            zip_code: '',
            country: '',
            phone: '',
            additional_info: '',
          }
        })
        .catch(() => {
          this.$toastr.e(this.t('actions.error'))
        })
          
      },
      
    }
  }

  </script>
  <style lang="scss">

    .v--modal--header--prize {
      img {
        height: 150px;
      }
    }


    .WidgetPrize {
      padding: 0;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__Image {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        &::before {
          content: "";
          padding-top: 64%;
          display: block;
          width: 100%;
          
        }
      }

      &__Bottom {
        color: white;
        background-image: linear-gradient(290deg, hsla(var(--color-primary)),hsla(var(--color-primary-dark)));
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        > div {
          min-height: 38px;
        }
        
      }
    }
  </style>