<template>
  <div class="CoursePathThumb Box Box--Clear Box--Thin" :id="`course_${course.id}`">
    <button class="Box__BgTitle" @click="thumbOpen = !thumbOpen">
      <h4 class="text-left">{{course.name}}</h4>
      <svg height="16" width="16" class="fill-current Boxed"> 
          <use v-if="thumbOpen" xlink:href="#icon-cheveron-up"></use>
          <use v-else xlink:href="#icon-cheveron-down"></use>
        </svg>
    </button>
     <transition name="slide">
      <div v-if="thumbOpen">
        <div class="CoursePathThumb__Progress flex items-center pt-3">
          <template v-if="course.progress_status">
            <span :class="['font-bold mr-2', {'text-success' : course.percentage_value> 0}]">{{ t(`pages.course.${course.progress_status}`)}}</span>
          </template>
          <template v-else>
            <span :class="['font-bold mr-2 text-sm', {'text-success' : course.percentage_value> 0}]" v-if="course.show_percentage">{{course.percentageCompleted}}</span>
            <div class="ProgressBar">
              <div  class="ProgressBar__Completed" :style="`width: ${course.percentageCompleted};`"></div>
            </div>
          </template>
        </div>
        <p  v-if="course.subtitle" class="mt-4 text-grayScale-middle">{{shortDescription(course.subtitle)}}</p>
        <div class="flex justify-between gap-2  align-baseline mt-6">
            <a  v-if="!notEnrolled && course.canShow" :href="`${course.nextLecture}`" class="Btn Btn--Success Btn--Arrow js-cookie-helper" data-cookie-name="related_path"  :data-cookie-value="pathId">
              {{ t(courseCta()) }}
              <svg height="16" width="16" class="fill-current"> 
                <use xlink:href="#icon-arrow-right"></use>
              </svg>
            </a>
            <button v-else class="cannot-view Btn Btn--Disabled Btn--Arrow" :content="notEnrolled ? t('pages.course.enroll_to_view') : t('pages.course.lecture_blocked')" v-tippy>{{ t(courseCta()) }}  
              <svg height="16" width="16" class="fill-current"> 
                <use xlink:href="#icon-arrow-right"></use>
              </svg>
            </button>
            <a :href="`/certifications/${course.completedCourse.unique_identifier}`" v-if="course.completedCourse && course.certification" class="Btn Btn--Text" target="_blank">
              <span class="hidden sm:block">{{ t('pages.home.certification')}}</span>
              <svg height="16" width="16" class="fill-current ml-2"> 
                <use xlink:href="#icon-download"></use>
              </svg>
            </a>
            <a v-if="course.show_comments" :href="`/courses/${course.id}/forum`" class="Btn Btn--Text">Forum</a>
            <div class="IconTextBox ">
              <template v-if="course.duration">
                <h4 class="pr-4">{{courseDuration}}</h4>
                <svg  class="fill-current Boxed"> 
                    <use xlink:href="#icon-time"></use>
                </svg>
              </template>
              <template v-else>
                <h4 class="pr-4">{{course.totLectures.length}} {{t(`global.lectures.${course.totLectures.length == 1 ? 's' : 'p'}`)}}</h4>
                <svg  class="fill-current Boxed"> 
                    <use xlink:href="#icon-book-reference"></use>
                </svg>
              </template>
            </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

import progressBar from './progressBar'

export default {

  components: {
    progressBar
  },

  props: {
    course: Object,
    pathId: String,
    notEnrolled: Boolean
  },

  data() {
    return {
      thumbOpen: true,
      
    }
  },


  computed: {
    courseDuration() {
      const totSeconds = this.course.duration;
      let seconds = totSeconds % 60;
      let minutes = Math.floor(totSeconds / 60);
      let hours = Math.floor(minutes / 60);
      minutes = minutes % 60; // get the remainder of minutes
      if (seconds >= 30) {
        minutes += 1;
      }
      return `${hours}h: ${minutes}m`
    },
  },


  methods: {
    shortDescription(description) {
      if (description) {
        if (description.length > 50) {
          return `${description.substr(0, 50)}...`
        } else {
          return description
        }
      } 
      return ''
    },

    courseCta() {
      switch(this.course.percentage_value) {
          case 0:
            return 'actions.start';
            break;
          case 100:
            return 'actions.restart';
            break;
          default:
            return 'general.continue'
      }
        
    },
  }
}
</script>

<style lang="scss" scoped>

  .ProgressBar {
    width: 100%;
    height: 8px;
    border-radius: 8px;
    background: var(--gray-light);
    overflow: hidden;
    &__Completed {
      background-color: #2AC76F;
      height: 100%;
    }
  }


</style>