<template>
  <div class="EditSection mb-4">
     <div class="flex items-center justify-between mb-4">
        <BackButton :href="`/admin/${course.path ? 'paths' : 'courses'}`" :cssClass="'mr-4'" />
        <!-- <p class="flex-1 font-bold text-lg pr-4">{{course.name}}</p> -->
        <div>
          <button v-if="canEdit" class="font-bold ai-button inline-flex items-center gap-2" @click.prevent="openGenerateSyllabus =  !openGenerateSyllabus">
            {{t('pages.course.course_generation.generate_with_ai')}}
            <img src="../../../images/icons/magic-wand.png" width="24"/>
          </button>
          <a :href="`/courses/${course.id}/${course.path ? 'path' : 'page'}?preview=true`" target="_blank" class="Btn Btn--Primary">Preview</a>
          <a :href="`../${course.id}/stats`" class="Btn Btn--Save">{{t('global.stats')}}</a>
          <a :href="`../${course.id}`" class="Btn Btn--Save">{{t('pages.course.invite_users_course')}}</a>
        </div>
     </div>
     <div v-if="openGenerateSyllabus" class="mt-4">
        <aiSyllabusGenerator :course="course" />
     </div>
     
    <form class="Form--Boxed mb-4 lg:grid gap-8 lg:grid-cols-2">
      <div class="EditSection__Infos">
      
        <div class="mb-2">
          <label for="course-name" class="text-lg">{{course.path ? t('pages.course.path_title') : t('pages.course.title')}}</label>
          <input 
              v-model="params.name"
              id="course-name"
              class="mb-4" 
              type="text" 
              :disabled="!canEdit"
              placeholder="Titolo..." 
              aria-label="Course Title" 
              />
        </div>
        <div class="mb-4">
          <label for="course-subtitle" class="text-lg items-center">
            {{course.path ? t('pages.course.path_subtitle') : t('pages.course.subtitle')}}
            <span class="font-normal text-sm ml-2 flex-1">
              ({{params.subtitle ? params.subtitle.length : 0}}/{{subtitleMax}})
            </span>
            <button :content="t('pages.course.subtitle_tooltip')" v-tippy class="Btn--Tooltip">
              <svg height="18" width="18" class="fill-current"> 
                <use xlink:href="#icon-information-outline"></use>
              </svg>
            </button>
          </label>
          <textarea 
              v-model="params.subtitle"
              id="course-subtitle"
              class="mb-2" 
              rows="5"
              :disabled="!canEdit"
              type="text" 
              placeholder="Inserisci una breve descrizione... " 
              :maxlength="subtitleMax"
              aria-label="Course Subtitle" /> 
    
          <label for="course-name" class="text-lg items-center justify-between">
            {{t('pages.course.description')}}
            <button :content="t('pages.course.description_tooltip')" v-tippy class="Btn--Tooltip">
              <svg height="18" width="18" class="fill-current"> 
                <use xlink:href="#icon-information-outline"></use>
              </svg>
            </button>
          </label>
          <vue-editor 
            :id="`editor${course.id}`"
            v-model="course.description" 
            :disabled="!canEdit"
            :editorToolbar="customToolbar"
            class="mb-4"
          >
          </vue-editor>
           <div >
             <label  v-if="course.path" for="" class="text-lg">{{t('pages.course.path_duration')}} {{pathDuration}}</label>
             <label  v-else-if="course.duration > 0" for="" class="text-lg">{{t('global.duration')}}: {{courseDuration}}</label>
             <label  v-if="isLacerbaAdmin && course.ext_academy !== ''">
              <span class="text-grayScale-middle mr-1">Ext_id:</span> {{course.ext_id}}, 
              <span class="text-grayScale-middle ml-3 mr-1">Ext_academy: </span>{{course.ext_academy}}
            </label>
           </div>
        </div>
      </div>
      <div class="EditSection__Options" >
        <p class="text-lg mb-4"><strong>{{t('pages.course.settings')}}:</strong></p>

        <div class="Collapse">
          <button class="Collapse__Button" @click.prevent="toggleCollapse('general')">
            <span>{{t('pages.course.settings_general')}}</span>
            <svg height="18" width="18" class="fill-current"> 
              <use :xlink:href="`#icon-cheveron-${collapse.general ? 'up' : 'down'}`"></use>
            </svg>
          </button>
          <div v-show="collapse.general" class="Collapse__Content">
            <div class="field flex items-center field--checkbox pt-2">
              <input type="checkbox" v-model="params.show_percentage" id="show-perc">  
              <label class="ml-2" for="show-perc"> {{t('pages.course.show_perc')}}</label>
            </div>
            
            <div v-if="features.comments" class="field flex items-center field--checkbox">
              <input type="checkbox" v-model="params.show_comments" id="show-comm">  
              <label class="ml-2" for="show-comm">{{t('pages.course.forum_enabled')}}</label>
            </div>

            <editCourseSetting v-if="blockForwardSetting":courseSetting="blockForwardSetting" :key="blockForwardSetting.id" />

            <editCourseSetting v-if="courseCode" :courseSetting="courseCode" :key="courseCode.id" />

            <div v-if="canEdit" class="field mt-4">
              <label for="language-select">{{t('global.language')}}</label>
              <select v-model="params.language" id="language-select" class="language-select">
                <option selected value="">Default</option>
                <option v-for="language in languages" :key="language.id" :value="language.code">
                  {{ language.code }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="Collapse mt-4" v-if="canEdit">
          <button class="Collapse__Button" @click.prevent="toggleCollapse('image')">
            <span>{{t('pages.course.cover_image')}}</span>
            <svg height="18" width="18" class="fill-current"> 
              <use :xlink:href="`#icon-cheveron-${collapse.image ? 'up' : 'down'}`"></use>
            </svg>
          </button>
          <div v-show="collapse.image" class="Collapse__Content">
            <div class="flex items-start gap-3 pt-2">
              <ImgUpload  :course="course" :name="t('pages.course.image_upload')"  :image_url="course.image_url" />
              <button class="font-bold ai-button flex items-center gap-2" @click.prevent="openGenerateImage">
                {{t('pages.course.image_generation.generate_with_ai')}}
                <img src="../../../images/icons/magic-wand.png" width="24"/>
              </button>
            </div>
          </div>
        </div>
            
        <div class="Collapse mt-4">
          <button class="Collapse__Button" @click.prevent="toggleCollapse('dates')">
            <span>{{t('pages.course.settings_dates')}}</span>
            <svg height="18" width="18" class="fill-current"> 
              <use :xlink:href="`#icon-cheveron-${collapse.dates ? 'up' : 'down'}`"></use>
            </svg>
          </button>
          <div v-show="collapse.dates" class="Collapse__Content">
            <div v-if="features.start_date" class="my-4">
              <label class="" for="">
                {{t('pages.course.start_date.label')}}
                <span  :content="t('pages.course.start_date.hint')" v-tippy class="Btn--Tooltip ml-2 mr-2 ">
                    <svg height="18" width="18" class="fill-current"> 
                      <use xlink:href="#icon-information-outline"></use>
                    </svg>
                  </span>
              </label>
              <datetime 
                :placeholder="t('actions.select_date')"
                type="datetime"
                v-model="params.start_date"
                class="mb-2 max-w-xl">
              </datetime>
            </div>
            <div v-if="features.end_date" class="my-4">
              <label class="" for="">
                {{t('pages.course.end_date.label')}}
                <span  :content="t('pages.course.end_date.hint')" v-tippy class="Btn--Tooltip ml-2 mr-2 ">
                    <svg height="18" width="18" class="fill-current"> 
                      <use xlink:href="#icon-information-outline"></use>
                    </svg>
                  </span>
              </label>
              <datetime 
                :placeholder="t('actions.select_date')"
                type="datetime"
                v-model="params.end_date"
                class="mb-2 max-w-xl">
              </datetime>
            </div>
          </div>
        </div>

        <div class="Collapse mt-4">
          <button class="Collapse__Button" @click.prevent="toggleCollapse('certification')">
            <span>{{t('pages.course.settings_certification')}}</span>
            <svg height="18" width="18" class="fill-current"> 
              <use :xlink:href="`#icon-cheveron-${collapse.certification ? 'up' : 'down'}`"></use>
            </svg>
          </button>
          <div v-show="collapse.certification" class="Collapse__Content">
            
            <div :class="['my-4 field flex items-center field--checkbox']">
              <input type="checkbox"  v-model="params.certification" id="show-cert">  
              <label class="ml-2" for="show-cert">{{t('pages.course.release_certification')}}</label>
            </div>

            <editCourseSetting v-if="linkedinOrganizationSetting" :courseSetting="linkedinOrganizationSetting" :key="linkedinOrganizationSetting.id" />

            <editCourseSetting v-if="customCertificationSetting" :courseSetting="customCertificationSetting" :key="customCertificationSetting.id" />
            <editCourseSetting v-if="durationCertificationSetting" :courseSetting="durationCertificationSetting" :key="durationCertificationSetting.id" />

          </div>
        </div>


        <div v-if="canEdit" :class="`border-l-4 border-${course.public ? 'success' : 'danger'} text-${course.public ? 'success' : 'danger'} px-4 my-6`" role="alert">
          <p class="font-bold">{{t(`pages.course.${course.public ? 'un' : ''}publish.text1`)}}</p>
          <p class="mb-2">{{t(`pages.course.${course.public ? 'un' : ''}publish.text2`)}}</p>
          <div class="flex items-center field--checkbox">
            <input type="checkbox" v-model="params.public" id="public">  
            <label class="ml-2" for="public"> {{t('pages.course.public')}}</label>
          </div>
        </div>
      </div>
      <div class="EditSections__Save text-right sm:col-start-1 sm:col-end-3">
        <button class="Btn Btn--Save" type="button" @click="updateCourse()">
        {{ t(`actions.${cta}`) }}
        </button>
      </div>
    </form>


    <div class="Form--Boxed mb-4">
      <div class="lg:grid lg:grid-cols-2 gap-8">
        <div>
          <label>{{t('pages.course.associated_areas')}}</label>
          <ul class="flex gap-2 mb-2 flex-wrap" v-if="courseAreas.length > 0">
            <li v-for="area in courseAreas" :key="area.id" class="Tag items-center">
              <span>{{area.name}}</span>
              <button @click="destroyArea(area)" class="ml-2" v-if="canEdit && !course.path">
                <svg height="12" width="12" class="fill-current"> 
                  <use xlink:href="#icon-close"></use>
                </svg>
              </button>
            </li>
          </ul>
          <p v-else>{{t('pages.course.no_associated_areas')}}</p>
        </div>
        <div v-if="canEdit">
          <div class="field" >
            <label>
              {{t('pages.course.add_area')}}
              <span  :content="t(`pages.course.add_area_info`)" v-tippy class="Btn--Tooltip ml-2 mr-2 ">
                <svg height="18" width="18" class="fill-current"> 
                  <use xlink:href="#icon-information-outline"></use>
                </svg>
              </span>
            </label>
            <input v-model="addingArea" type="text" class="mb-2" placeholder="Es. 'marketing' o 'soft-skills'" />
            <button class="Btn Btn--Save" @click="addArea">{{t('actions.save')}}</button>
          </div>
          <div class="field" >
            <label>{{t('pages.course.associate_area')}}</label>
            <v-select
              :options="course.from_lacerba ? areasOptions : subjectsOptions"
              :searchable="false"
              v-model="selectedArea"
              class="mb-2"
            />
            <button class="Btn Btn--Save" @click="associateArea">{{t('actions.save')}}</button>
          </div>
        </div>
      </div>
    </div>


    <div class="grid grid-cols-2 gap-4">
      <div  class="Form--Boxed mb-4">
       <label class="ml-2">{{t('pages.course.registration_method')}}</label>
        <div class="field" >
          <v-select
            :options="enrollTypeOptions"
            :value="enrollType"  
            :clearable="false"
            @input="selection => updateSelectedEnrollType(selection)"
          />
        </div>
        
        <button @click="updateCourse()" class="Btn Btn--Save mb-4"> {{ t(`actions.${cta}`) }}</button>
        <p v-if="enrollUrl"> {{ enrollUrl }}</p>
      </div>

      
      <div v-if="features.gamification" class="Form--Boxed mb-4">
        <label class="text-lg">{{t('pages.course.gamification.caption')}}</label>
        <div class="mb-2">
          <label for="course-name">{{t('pages.course.gamification.points')}}</label>
          <input v-model="params.gamification_points" id="points" class="mb-4" type="number" :placeholder="t('pages.prizes.points')"
            aria-label="Punti" />
        </div>
        <button @click="updateCourse()" class="Btn Btn--Save mb-4"> {{ t(`actions.${cta}`) }}</button>
      </div>

    </div>

    <modal name="generate-image" >
        <div class="v--modal--header p-4">
          <h2 class="text-xl flex items-center gap-3">
            <img src="../../../images/icons/magic-wand.gif" width="24"/>
            {{ t('pages.course.image_generation.generate_cover') }}
         </h2>
        </div>
        <div class="v--modal--body body--users p-4">
          <div v-if="!aiLoading && aiGenImage === ''">
            <textarea 
                  v-model="prompt"
                  id="generate-image"
                  class="mb-2" 
                  rows="5"
                  type="text" 
                  :placeholder="`${t('pages.course.image_generation.placeholder')}`" 
                  aria-label="Course Subtitle" /> 
          <button class="Btn Btn--Save mb-2" @click="generateImage">{{t('pages.course.image_generation.generate')}}</button>
          </div>

          <div v-if="aiLoading">
            <div class="flex items-center justify-center image-placeholder animated-background mb-3">
              <p  class="px-10 ai-loading text-lg font-bold">{{t('pages.course.image_generation.loading')}}</p>
              <img class="" v-if="aiGenImage" :src="aiGenImage" alt="ai generated image" />
            </div>
          </div>
          <div v-if="aiError">
            <p class="text-danger font-bold">{{t('pages.course.image_generation.error')}}: {{ aiError }}</p>
          </div>
          <button class="Btn Btn--Save" v-if="aiGenImage" @click="uploadImage">{{ t('actions.save') }}</button>
          <button class="Btn Btn--Delete" v-if="aiGenImage" @click="clearImage">{{t('actions.delete')}}</button>
        </div>
    </modal>

  </div>
</template>

<script>
import api from '../../../api'
import enrollsList from '../../commons/enrollsList'
import BackButton from '../../commons/backButton'
import editCourseSetting from '../../commons/editCourseSetting'
import ImgUpload from '../../commons/imgUpload'
import { mapState, mapActions, mapGetters } from 'vuex'
import { VueEditor } from "vue2-editor";
import { global as EventBus } from '../../../js/utils/eventBus';
import aiSyllabusGenerator from '../../admin/lecture/aiSyllabusGenerator'



export default {
  name: 'AdminCourseEdit',
  props: {
    course: Object,
    courseSettings: Array,
    isLacerbaAdmin: Boolean,
    features: Object
  },
  components: {
    BackButton,
    editCourseSetting,
    ImgUpload,
    VueEditor,
    aiSyllabusGenerator
  },
  data() {
    return {
      openGenerateSyllabus: false,
      cta: "save",
      enroll_type_id: Number,
      enrollUrl: null,
      enrollTypeOptions: [
        {label: 'Join by invitation', value: 1}, 
        {label: 'Open', value: 2},
        {label: 'Approve/Decline', value: 3}
        // {label: 'Whitelist Domain only', value: 4}
      ],
      subtitleMax: 250,
      originalLinkedinOrganizationId: '',
      canEdit: true,
      params: {
        name: this.course.name,
        subtitle: this.course.subtitle,
        show_percentage: this.course.show_percentage,
        show_comments: this.course.show_comments,
        certification: this.course.certification,
        public: this.course.public,
        start_date: this.course.start_date,
        end_date: this.course.end_date,
        language: this.course.language || '',
        gamification_points: this.course.points,
        linkedin_organization_id: ''
      },
      selectedArea: {},
      customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["code-block"],["link"], ["clean"]],
      addingArea: '',
      languages: [],
      prompt: '',
      aiGenImage: '',
      aiLoading: false,
      aiError: '',
      collapse: {
        image: false,
        dates: false,
        general: true,
        certification: false
      }
    }
  },
  computed: {
    ...mapState({
      courseAreas: state => state.course.areas,
      areas: state => state.area.areas
    }),
 
    canEdit(){
      return !this.course.ext_academy || this.isLacerbaAdmin
    },

    enrollType(){
      let currentEnrollType = this.enroll_type_id
      return this.enrollTypeOptions.filter(option => option.value === currentEnrollType)
    },
 

    blockForwardSetting() {
      return this.courseSettings.find(setting => setting.name === 'block_forward')
    },

    customCertificationSetting() {
      return this.courseSettings.find(setting => setting.name === 'custom_certification')
    },

    durationCertificationSetting() {
      return this.courseSettings.find(setting => setting.name === 'duration_certification')
    },

    linkedinOrganizationSetting() {
      return this.courseSettings.find(setting => setting.name === 'linkedin_organization_id');
    },

    courseCode() {
      return this.courseSettings.find(setting => setting.name === 'code');
    },

    pathDuration() {
      let duration = 0;
      this.course.related_courses.forEach(el => {
        if (el.duration) duration += el.duration
      });
      const totSeconds = duration;
      let seconds = totSeconds % 60;
      let minutes = Math.floor(totSeconds / 60);
      let hours = Math.floor(minutes / 60);
      minutes = minutes % 60; // get the remainder of minutes
      if (seconds >= 30) {
        minutes += 1;
      }
      return `${hours}h: ${minutes}m`
    },

    courseDuration() {
      const totSeconds = this.course.duration;
      let seconds = totSeconds % 60;
      let minutes = Math.floor(totSeconds / 60);
      let hours = Math.floor(minutes / 60);
      minutes = minutes % 60; // get the remainder of minutes
      if (seconds >= 30) {
        minutes += 1;
      }
      return `${hours}h: ${minutes}m`
    },
    
    areasOptions() {
      const areas = this.areas.map(el => (
        {
          label: el.name,
          id: el.id
        }
      ))
      return areas
    },
    subjectsOptions() {
      const subjects = this.areas.filter(el => el.area_type === 'subject' || el.area_type === 'home')
      const areas = subjects.map(el => (
        {
          label: el.name,
          id: el.id
        }
      ))
      return areas
    },
    hasChanges() {
      return this.params.linkedin_organization_id !== this.originalLinkedinOrganizationId;
    },
  },
  mounted(){
    this.enroll_type_id = this.course.enroll_type_id
    this.getEnrollUrl()
    this.getAllCourseAreas()
    this.getAllAreas()
    const langSetting = this.course.course_settings.find(setting => setting.name === 'lang');
    this.selectedLang = langSetting ? langSetting.value : null;
    this.fetchLanguages();
    console.log('Course settings:', this.course.course_settings)
    const linkedinSetting = this.course.course_settings.find(setting => setting.name === 'linkedin_organization_id');
    if (linkedinSetting) {
      this.params.linkedin_organization_id = linkedinSetting.value;
    }
    this.originalLinkedinOrganizationId = this.params.linkedin_organization_id;
    EventBus.on('close:ai-syllabus-generator', () => {
      this.openGenerateSyllabus = false;
    });

    console.log('cazz0')
    console.log(this.courseSettings)
  },
  methods: {
    ...mapActions({
      getCourseAreas: 'getCourseAreas',
      getAreas: 'getAreas'
    }),


    toggleCollapse(section) {
      this.collapse[section] = !this.collapse[section];
    },
    
    fetchLanguages() {
      api.Language.getLanguagesApi().then(data => {
        console.log("Lingue ricevute:", data);
        this.languages = data;
      }).catch(error => {
        console.error("Errore nel recupero dei codici delle lingue:", error);
      });
    },

    getAllAreas() {
      api.Area.getAreasApi()
        .then(res => {
          this.getAreas(res)
        })
    },

    getAllCourseAreas() {
      api.Course.getCourseAreasApi(this.course.id)
      .then(res => {
        this.getCourseAreas(res.course_areas)
      })
    },

    async addArea() {
      try {
        const res = await api.Area.create({name: this.addingArea, area_type: 'subject'})
        this.addingArea = ''
        const areas = await api.Area.getAreasApi()
        this.getAreas(areas)
        if (res.status === 'Area already exists') {
          this.$toastr.e(this.t('errors.messages.area_exists'));
        } else {
          this.$toastr.s(this.t('pages.course.area_added'));
        }
      } catch (error) {
        this.$toastr.e(err);
      }
    },

    updateCourse() {
      const target = event.currentTarget ;
      EventBus.emit('loader:local:start', { elem: target })
      let course = {
        ...this.course,
        ...this.params
      }
      api.Course.update(course)
        .then((res) => {
          this.course = res
          this.getEnrollUrl()
          this.$toastr.s(this.t('actions.saved'));
          EventBus.emit('loader:local:end', { elem: target })
        })
        .catch(e =>{
          this.$toastr.e(this.t('errors.messages.try_again'));
          EventBus.emit('loader:local:end', { elem: target })
        })
    },
    associateArea() {
        api.Course.associateArea(this.course.id, this.selectedArea.id) 
          .then(res => {
            this.getCourseAreas(res.course_areas)
          })
    },
    updateSelectedEnrollType (selection) {
      this.enroll_type_id = selection.value
      this.course.enroll_type_id = selection.value
    },
    getEnrollUrl(){
      if (this.course.enroll_type_id != 1 && this.course.uuid ){
        this.enrollUrl = window.location.hostname+'/enrolls/'+this.course.uuid
      }
      else {
        this.enrollUrl = null
      }
    },
    destroyArea(area) {
      if(confirm( `Sei sicuro di voler eliminare l'area?` )){
        api.Course.destroyArea(area.ass_id)
        .then(res => {
          this.getCourseAreas(res.course_areas)
        })
        .catch(err => {
          this.$toastr.e(err);
        })
      }
    },

    generateImage() {
      console.log(this.prompt)
      this.aiLoading = true
      api.Course.generateImage({
        course_id: this.course.id, 
        prompt: this.prompt
      })
        .then(res => {
          console.log(res)
          //this.aiLoading = false
          //polly ajax
          const intervalId = setInterval(() => {
            api.Course.checkJobStatus({
              course_id: this.course.id,
              job_id: res.job_id
            })
            .then(resp => {
              if (resp.status == 'error') {
                this.aiError = resp.error_code
                this.aiLoading = false;
                clearInterval(intervalId); // Stop polling
              } else if (resp.image_url) {
                this.aiGenImage = resp.image_url;
                clearInterval(intervalId); // Stop polling
              }
            })
            .catch(err => {
              this.$toastr.e(err);
              clearInterval(intervalId); // Stop polling if an error occurs
              this.aiLoading = false;
            });
          }, 1500);
        } )
        .catch(err => {
          this.aiLoading = false
          this.aiError = err
          this.$toastr.e(err);
        })

    },

   uploadImage() {
    const target = event.currentTarget;
    EventBus.emit('loader:local:start', { elem: target })
     api.Course.uploadImage({
      course_id: this.course.id,
      image_url: this.aiGenImage
     })
     .then(res => {
        console.log(res)
        EventBus.emit('loader:local:end', { elem: target })
        location.reload()
      } )
      .catch(err => {
        this.$toastr.e(err);
        EventBus.emit('loader:local:end', { elem: target })
      })
    },

    openGenerateImage() {
      this.$modal.show('generate-image');
    },

    clearImage() {
      this.aiGenImage = '';
      this.aiLoading = false;
    }
    
  }
    
}

</script>
<style lang="scss">



.ai-loading {
  color: #bcb2fb;
}

.EditSection {
  .ql-editor {
    max-height: 320px;
  }
}

.language-select {
  text-transform: uppercase;
}

.image-placeholder {
 position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
 }
}

</style>