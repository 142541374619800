import AcademySetting from "./academySetting";
import Coach from "./coach";
import CompletedLecture from "./completedLecture";
import Comment from "./comment";
import Content from "./content";
import Course from "./course";
import Certification from "./certification";
import CourseSetting from "./courseSetting";
import Enroll from "./enroll";
import Lecture from "./lecture";
import Message from "./message";
import Page from "./page";
import Quiz from "./quiz";
import QuizAttempt from "./quizAttempt";
import Style from "./style";
import Room from "./room";
import User from "./user";
import UserActivity from "./userActivity";
import UserField from "./userField";
import UserSetting from "./userSetting";
import General from "./general";
import Zoom from "./zoom";
import ScormCloud from "./scormCloud";
import Feature from "./feature";
import Webhook from "./webhook";
import Stats from "./stats";
import Area from "./area";
import CustomNotification from "./customNotification";
import Language from "./language";
import Prize from "./prize";
import Group from "./group";
import GroupCourse from "./groupCourse";
import Badge from "./badge";
import BadgeAssignmentCriteria from "./badgeAssignmentCriteria";
import Leaderboard from "./leaderboard";
import LeaderboardBadge from "./leaderboardBadge";
import HomeLayout from "./homeLayout";
import shippingAddres from "./shippingAddres";

export default {
  AcademySetting,
  Area,
  Coach,
  CompletedLecture,
  Comment,
  Content,
  Course,
  Certification,
  CourseSetting,
  Enroll,
  Lecture,
  Message,
  Page,
  Quiz,
  QuizAttempt,
  Style,
  Room,
  User,
  UserActivity,
  UserField,
  UserSetting,
  General,
  Zoom,
  Feature,
  Webhook,
  ScormCloud,
  Feature,
  Stats,
  CustomNotification,
  Language,
  Prize,
  Group,
  GroupCourse,
  Badge,
  BadgeAssignmentCriteria,
  Leaderboard,
  LeaderboardBadge,
  HomeLayout,
  shippingAddres,
};
